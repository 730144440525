// @flow

import React, { Component } from "react";
import styled, { keyframes } from "styled-components";
import { hiDPI } from "polished";

import { COLORS } from "../constants";
import { ColorType } from "../dataTypes";
import SecureIcon from "../images/svg/secure-icon.svg";
import SecureIconV2 from "../images/svg/secure-icon-v2.svg";
import LoadingIcon from "../images/svg/loading-icon.svg";
import NarrowArrow from "../images/svg/narrow-arrow.svg"

export type ButtonProps = {
  loading?: boolean,
  disabled?: boolean,
  color?: ColorType,
  size?: "big" | "medium" | "small" | "xs",
  href?: string,
  minWidth?: string,
  children: any,
  borderRadius?: number,
  inline?: boolean,
  type?: "button" | "submit",
  height?: number,
  className?: string
};

export type ButtonWithIconProps = {
  ...ButtonProps,
  icon?: "arrow" | "hand" | "secure" | "secureV2",
  text?: string,
  rotation?: "top" | "bottom" | "left" | "right"
}

export const ArrowIcon = styled.span`
  display: inline-block;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  position: relative;

  ${
    props => {
      if (props.bgcolor) {
        return "background-color: " + props.bgcolor +";"
      } else if (props.color && COLORS[props.color]) {
        return "background-color: " + COLORS[props.color].text + ";"
      } else {
        return "background-color: #FFF;"
      }
    }
  }
  top: 6px;
  margin-left: 12px;

  ${
    props => {
      if (!props.rotation) {
        return
      } else {
        if (props.rotation === "right") {
          return "transform: rotate(0deg);"
        } else if (props.rotation === "bottom") {
          return "transform: rotate(90deg);"
        } else if (props.rotation === "left") {
          return "transform: rotate(180deg);"
        }  else if (props.rotation === "top") {
          return "transform: rotate(270deg);"
        }
      }
      
    }
  }

  i {
    background-color: currentColor;
    ${
      props => {
        if (props.disabledColor && props.disabled) {
          return "color: " + props.disabledColor +";"
        } else if (props.color && COLORS[props.color]) {
          return "color: " + COLORS[props.color].mainColor + ";"
        } else if (props.arrowcolor) {
          return "color: #" + props.arrowcolor + ";"
        } else if (props.color) {
          return "color: #" + props.color + ";"
        }  else {
          return "color: #fff;"
        }
      }
    }
    left: 4px;
    display: inline-block;
    height: 2px;
    position: absolute;
    width: 10px;
    top: 9px;

    &::before{
      content: '';
      position: absolute;
      right: 0px;
      top: -3px;
      width: 6px;
      height: 6px;
      border-top: solid 2px currentColor;
      border-right: solid 2px currentColor;
      -webkit-transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
`

const SecureIconWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 14px;
  width: 16px;
  height: 16px;
  margin-top: -7px !important;

  svg {
    display: block;
    margin: 0px;
  }
`;

const SecureIconWrapperV2 = styled.div`
  position: absolute;
  top: calc(50% - 10px);
  left: 14px;
  width: 21px;
  height: 20px;

  svg {
    display: block;
    margin: 0px;
  }
`;

class Button extends Component<ButtonProps> {
  static defaultProps = {
    href: "javascript:;",
    size: "big",
    color: "cyan",
    active: true,
    disabled: false,
    loading: false,
    textSize: "normal"
  };

  render() {
    const { children, type, buttonRef, ...rest } = this.props;
    return (
      <ButtonWrap ref={buttonRef} {...rest}>
        <span>{children}</span>
        {rest.loading && <Loading />}
        {type == "submit" ? (
          <input type="submit" style={{ display: "none" }} />
        ) : null}
      </ButtonWrap>
    );
  }
}

class ButtonWithIcon extends Component<ButtonWithIconProps> {
  static defaultProps = {
    href: "javascript:;",
    size: "big",
    color: "cyan",
    active: true,
    disabled: false,
    loading: false,
    textSize: "normal",
    rotation: "right",
    icon: "arrow"
  };

  render() {
    const { text, icon, color, ...rest } = this.props;

    const ICONS = {
      arrow: (
        <ArrowIcon
          bgcolor={this.props.bgcolor}
          color={color}
          rotation={this.props.rotation}
          disabled={this.props.disabled}
          disabledColor={this.props.disabledColor}
          className="arrow-icon"
          color={color}
        >
          <i />
        </ArrowIcon>
      ),
      narrowArrow: <NarrowArrow/>,
      hand: null,
      secure: <SecureIconWrapper><SecureIcon fill={color && COLORS[color] ? COLORS[color].text : "#fff"}/></SecureIconWrapper>,
      secureV2: <SecureIconWrapperV2><SecureIconV2 fill={color && COLORS[color] ? COLORS[color].text : "#fff"}/></SecureIconWrapperV2>,
    }

    return (
      <Button { ...rest } color={color}>
        {text}
        {ICONS[icon]}
      </Button>
    );
  }
}

const ButtonCentered = (props: ButtonProps) => (
  <ButtonCenteredWrap>
    <Button {...props} inline />
  </ButtonCenteredWrap>
);

export { ButtonCentered, ButtonWithIcon };
export default Button;

const spin = keyframes`
  100% { transform: rotate(360deg) };
`;

const ButtonWrap = styled.a`
  height: ${props => (props.height ? props.height : heightSize[props.size])}px;
  line-height: ${props =>
    props.height ? props.height : heightSize[props.size]}px;
  border-radius: ${props => (props.borderRadius ? props.borderRadius : 5)}px;
  color: ${(props) => {
    if (props.disabledTextColor && props.disabled) {
      return props.disabledTextColor;
    }

    if (props.color && COLORS[props.color]) {
      return COLORS[props.color].text;
    }

    return COLORS["cyan"].text;
  }};
  font-family: Roboto, Arial, Tahoma, Helvetica, sans-serif !important;
  text-decoration: none;
  text-align: center;
  cursor: ${props => (props.disabled ? "default" : "pointer")};
  font-weight: ${({ fontWeight }) => fontWeight || 600};
  font-size: ${props => textSize[props.textSize]}px;
  position: relative;
  width: inherit;
  min-width: ${props => (props.minWidth ? props.minWidth : "auto")};
  padding: ${props => (props.padding ? props.padding : "0 20px")};
  margin: 0 auto;
  display: inline-block;
  box-sizing: border-box;
  border: ${props =>  COLORS[props.color] ? COLORS[props.color].border :  COLORS["cyan"].border };
  opacity: ${props => (props.disabled && !props.disabledColor ? 0.5 : 1)};
  & > span {
    visibility: ${props => (props.loading ? "hidden" : "visible")};
  }
  background: ${(props) => {
    if (props.disabledColor && props.disabled) {
      return props.disabledColor;
    }

    if (props.color && COLORS[props.color]) {
      if (COLORS[props.color].secondColor) {
        return 'linear-gradient(to bottom, ' + COLORS[props.color].mainColor + ', ' + COLORS[props.color].secondColor + ')';
      }
      return COLORS[props.color].mainColor;
    } else {
      return COLORS["cyan"].mainColor;
    }
  }};
  transition: all 250ms ease-in-out;
  transition-delay: ${props => (props.transitionDelay ? props.transitionDelay : "0ms")};
  ${props => buttonSize(props)};
`;

const ButtonCenteredWrap = styled.div`
  text-align: center;
`;

export const Loading = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin: -10px 0 0 -10px;
  -webkit-animation: ${spin} 0.5s linear infinite;
  animation: ${spin} 0.5s linear infinite;
  background: url(${require("../images/loading_button.png")}) no-repeat 50%;
  background-size: 20px 20px;
`;

function buttonSize(props: ButtonProps) {
  return props.inline
    ? {
        display: "inline-block",
        margin: "0 auto"
      }
    : {
        display: "block",
        width: "100%"
      };
}

const heightSize = {
  big: 40,
  medium: 34,
  xm: 26,
  small: 20
};

const textSize = {
  huge: 18,
  bigger2: 16,
  bigger: 15,
  normal: 13,
  small: 11
};

const IconWrapper = styled.div`
  position: absolute;
  top: 3px;
  left: 14px;
`;
